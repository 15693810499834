const faqData = [
  {
    question: "지식이 없어도 제품 사용하는데 문제가 없나요?",
    answer:
      "네, 지식이 전혀 없는 분들도 손쉽게 사용하실 수 있도록 메뉴얼을 제공하고 있습니다.",
  },
  {
    question: "지식이 없어도 제품 사용하는데 문제가 없나요?",
    answer:
      "네, 지식이 전혀 없는 분들도 손쉽게 사용하실 수 있도록 메뉴얼을 제공하고 있습니다.",
  },
  {
    question: "지식이 없어도 제품 사용하는데 문제가 없나요?",
    answer:
      "네, 지식이 전혀 없는 분들도 손쉽게 사용하실 수 있도록 메뉴얼을 제공하고 있습니다.",
  },
];

export default faqData;
