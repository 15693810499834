import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../../components/Nav";
import main2Styles from "../../assets/pagesStyle/main/main2.module.css";
import main2Img from "../../assets/images/rectangle.png";

const Main2 = ({ isActive }) => {
  const main2Ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      // 스와이퍼 슬라이드가 활성화되었을 때 애니메이션 효과를 부여
      main2Ref.current.classList.add(main2Styles.active);
    } else {
      // 비활성화 시 클래스 제거
      main2Ref.current.classList.remove(main2Styles.active);
    }
  }, [isActive]);

  return (
    <>
      <Nav />
      <div ref={main2Ref} className={main2Styles.main2}>
        <div className={main2Styles.main2flex}>
          <div className={main2Styles.imgBox}>
            <img src={main2Img} alt="main2이미지" />
          </div>
          <div className={main2Styles.txtBox}>
            <div className={main2Styles.txts}>
              <h1>기술의 미래를 선도하는 와이시스템즈</h1>
              <p>
                기술 발전의 빠른 흐름에 맞춰, 우리는 신규 및 미래지향적 기술에
                중점을 둔 자동화 설비를 제조합니다. 반도체와 2차전지 분야에서
                사람과 기술의 조화를 추구하며, 연구개발에 헌신적으로 투자하고
                있습니다.
              </p>
              {/* 클릭 시 기업소개 페이지 이동 */}
              <Link to="/intro">기업소개</Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main2;
