import { useState, useEffect } from "react";

const useCountUp = (target, duration) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = parseInt(target, 10);
    if (end === 0) {
      setCount(0);
      return;
    }
    const stepTime = duration / (end - start); // Time per increment
    const interval = setInterval(() => {
      start += 1;
      if (start >= end) {
        setCount(end);
        clearInterval(interval);
      } else {
        setCount(start);
      }
    }, stepTime);

    return () => clearInterval(interval); // Cleanup interval on unmount or target change
  }, [target, duration]);

  return count;
};

export default useCountUp;
