import React from "react";
import loadingStyles from "../../assets/pagesStyle/loading/loading.module.css";

const Loading = () => {
  return (
    <div className={loadingStyles.loading}>
      <div className={loadingStyles.spinner}></div>
      <p>로딩 중...</p>
    </div>
  );
};

export default Loading;
