import React from "react";
import navStyles from "../assets/pagesStyle/nav/nav.module.css"; // CSS 모듈 가져오기
import { Link } from "react-router-dom";

const Nav = ({ isActive }) => {
  return (
    <div
      className={`${navStyles.navBar} ${isActive ? navStyles.activeNav : ""}`}
    >
      <div className={navStyles.logoBox}>
        <Link to="/">
          <h1>로고</h1>
        </Link>
      </div>
      <Link to="/intro" className={navStyles.link}>
        회사소개
      </Link>
      <Link to="/product" className={navStyles.link}>
        제품소개
      </Link>
      <Link to="/promote" className={navStyles.link}>
        홍보센터
      </Link>
      <Link to="/customer" className={navStyles.link}>
        고객지원
      </Link>
      <Link to="/recruit" className={navStyles.link}>
        인재채용
      </Link>
    </div>
  );
};

export default Nav;
