import React from "react";
import companyStyles from "../../assets/pagesStyle/intro/company.module.css";
import companyName from "../../assets/images/companyName.svg";
import CEO from "../../assets/images/CEO.svg";
import address from "../../assets/images/address.svg";

const CompanyTop = () => {
  return (
    <div className={companyStyles.companyTop}>
      <div className={companyStyles.txtBox}>
        <h1>회사개요</h1>
        <p>
          최고의 품질을 위해 항상 노력하고 안전을 최우선하는 전문기업
          <br /> 우리 회사는 고객이 필요한 것을 먼저 생각합니다.
        </p>
      </div>
      <div className={companyStyles.flex}>
        <div className={companyStyles.intro}>
          <img src={companyName} alt="회사아이콘" />
          <h2>회사명</h2>
          <p>YSYSTEM</p>
        </div>
        <div className={companyStyles.intro}>
          <img src={CEO} alt="CEO아이콘" />
          <h2>CEO</h2>
          <p>최치영</p>
        </div>
        <div className={companyStyles.intro}>
          <img src={address} alt="주소아이콘" />
          <h2>본사 소재지</h2>
          <p>평택시 고덕중앙로 290, 9층 27호</p>
        </div>
      </div>
    </div>
  );
};

export default CompanyTop;
