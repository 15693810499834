import React, { useState, useEffect, useRef } from "react";
import Nav from "../../components/Nav";
import main8Styles from "../../assets/pagesStyle/main/main8.module.css";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import headset from "../../assets/images/headset.png";
import paper from "../../assets/images/paper.png";
import FooterMain from "../footer/FooterMain";

const Main8 = ({ isActive }) => {
  const [items, setItems] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const main8Ref = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function getUser() {
      const response = await axios.get(
        "https://y-system.synology.me:8080/news"
      );

      const data = response.data;
      setItems(data);
    }
    getUser();
  }, []);

  useEffect(() => {
    if (isActive) {
      // 스와이퍼 슬라이드가 활성화되었을 때 애니메이션 효과를 부여
      main8Ref.current.classList.add(main8Styles.active);
    } else {
      // 비활성화 시 클래스 제거
      main8Ref.current.classList.remove(main8Styles.active);
    }
  }, [isActive]);

  const handleButtonClick = () => {
    setIsToggle(!isToggle);
  };

  const supportLink = () => {
    navigate("/customer", { state: { activeTab: "문의하기" } });
  };
  const recruitLink = () => {
    navigate("/recruit", { state: { activeTab: "Notice" } });
  };

  return (
    <>
      <Nav />
      <div
        className={`${main8Styles.main8} ${isToggle ? main8Styles.toggle : ""}`}
        ref={main8Ref}
      >
        <div className={main8Styles.main8Box}>
          <div className={main8Styles.main8txtBox}>
            <div className={main8Styles.txtBox}>
              <h1>뉴스미디어</h1>
              <h2>와이시스템 새소식을 확인해보세요</h2>
            </div>
            <Link to="/promote">+ 더보기</Link>
          </div>
          <div className={main8Styles.newsBox}>
            {items.slice(0, 4).map((item) => (
              <div key={item.news_id} className={main8Styles.newsItem}>
                <h3>{item.news_title}</h3>
                <Link to={`/news/${item.news_id}`}>
                  more
                  <em />→
                </Link>
              </div>
            ))}
          </div>
        </div>

        <div className={main8Styles.linkBox}>
          <div className={main8Styles.recruit}>
            <img src={paper} alt="paper" />
            <div className={main8Styles.link}>
              <h3>인재채용</h3>
              <button onClick={recruitLink}>채용공고 바로가기</button>
            </div>
            <span />
          </div>
          <div className={main8Styles.support}>
            <img src={headset} alt="headset" />
            <div className={main8Styles.link}>
              <h3>고객지원</h3>
              <button onClick={supportLink}>고객문의 바로가기</button>
            </div>
          </div>
        </div>
        <button
          onClick={handleButtonClick}
          className={`${main8Styles.btn} ${isToggle ? main8Styles.toggle : ""}`}
        ></button>
      </div>
      <FooterMain
        className={isToggle ? main8Styles.toggleFooter : main8Styles.footer}
      />
    </>
  );
};

export default Main8;
