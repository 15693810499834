import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Nav from "./Nav";
import FAQ from "../pages/customer/FAQ";
import Techsupport from "../pages/customer/Techsupport";
import Ask from "../pages/customer/Ask";
import customerStyles from "../assets/pagesStyle/customer/customer.module.css";
import MobileNav from "./MobileNav";

const Customer = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState("문의하기");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={customerStyles.customer}>
      {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
      <div className={customerStyles.customerTop}>
        <h2>CUSTOMER</h2>
        <h1>고객지원</h1>
      </div>
      <div className={customerStyles.customerFlex}>
        {/* <Link
          className={isActive === "FAQ" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("FAQ")}
        >
          FAQ
        </Link> */}
        <Link
          className={isActive === "기술지원" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("기술지원")}
        >
          기술지원
        </Link>
        <Link
          className={isActive === "문의하기" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("문의하기")}
        >
          문의하기
        </Link>
      </div>
      <div className={customerStyles.pages}>
        {isActive === "FAQ" && <FAQ />}
        {isActive === "기술지원" && <Techsupport />}
        {isActive === "문의하기" && <Ask />}
      </div>
    </div>
  );
};

export default Customer;
