import React from "react";
import techStyles from "../../assets/pagesStyle/product/tech.module.css";
import img from "../../assets/images/product/product1.png";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../footer/Footer";
import more1 from "../../assets/images/more1.svg";
import more2 from "../../assets/images/more2.svg";
import more3 from "../../assets/images/more3.svg";
const Technology = () => {
  const navigate = useNavigate();
  const supportLink = () => {
    navigate("/customer", { state: { activeTab: "문의하기" } });
  };
  return (
    <>
      <div className={techStyles.tech}>
        <div className={techStyles.techBox}>
          <div className={techStyles.flex}>
            <div className={techStyles.imgBox}>
              <img src={img} alt="이미지" />
            </div>
            <div className={techStyles.info}>
              <h1>Technology</h1>
              <div className={techStyles.txt}>
                <h2>제품명</h2>
                <p>REEL TO REEL COUNT INSPECTION</p>
              </div>
              <div className={techStyles.txt}>
                <h2>정확도</h2>
                <p>INSPECTION ACCURACY +/- 30um</p>
              </div>
              <div className={techStyles.txt}>
                <h2>카메라</h2>
                <p>120M AREA SCAN CAMERA</p>
              </div>
              <div className={techStyles.txt}>
                <h2>사이즈</h2>
                <p>PCB SIZE : 8 x 4 mm</p>
              </div>
              <div className={techStyles.txt}>
                <h2>간격</h2>
                <p>PCB PICTH : 8 ~ 16 mm</p>
              </div>
              <div className={techStyles.btnBox}>
                <Link className={techStyles.btn}>
                  <div className={techStyles.downBtn}>제품소개 다운로드</div>
                </Link>
                <button onClick={supportLink} className={techStyles.btn}>
                  <div>제품 문의하기</div>
                </button>
              </div>
            </div>
          </div>
          <div className={techStyles.flex}>
            <div className={techStyles.more}>
              <div className={techStyles.moreBox}>
                <img src={more1} alt="more1" />
              </div>
              <h2>머신비전 솔루션</h2>
              <p>편리한 비전티칭</p>
            </div>
            <div className={techStyles.more}>
              <div className={techStyles.moreBox}>
                <img src={more2} alt="more2" />
              </div>
              <h2>반도체 규격설비</h2>
              <p>높은 안정성과 정밀한 설비설계</p>
            </div>
            <div className={techStyles.more}>
              <div className={techStyles.moreBox}>
                <img src={more3} alt="more3" />
              </div>
              <h2>품질대응</h2>
              <p>효율적이고 안정적인 품질대응</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Technology;
