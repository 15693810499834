import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "./Nav";
import customerStyles from "../assets/pagesStyle/customer/customer.module.css";
import downloadStyles from "../assets/pagesStyle/promote/download.module.css";
import downBtn from "../assets/images/downBtn.svg";
import Footer from "../pages/footer/Footer";
import Loading from "../pages/loading/Loading";
import axios from "axios";
import useAuth from "../assets/hooks/useAuth";
import MobileNav from "./MobileNav";

const ReplyDetail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [reply, setReply] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isActive, setIsActive] = useState("문의하기");
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    async function getReply() {
      const response = await axios.get(
        "https://y-system.synology.me:8080/reply"
      );
      const data = response.data;
      setReply(data);
    }
    getReply();
  }, []);

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    async function viewCount() {
      try {
        await axios.post(`https://y-system.synology.me:8080/reply/${id}/view`);
      } catch (error) {
        console.error("Error incrementing view count:", error);
      }
    }
    viewCount();
  }, [id]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  const replyItem = reply.find((item) => item.reply_id === parseInt(id));
  if (!replyItem) {
    return <Loading />;
  }

  const currentIndex = reply.findIndex(
    (item) => item.reply_id === parseInt(id)
  );
  const prevItem = reply[currentIndex - 1];
  const nextItem = reply[currentIndex + 1];

  const listBtn = () => {
    navigate("/customer");
  };

  const goToPrev = () => {
    if (prevItem) {
      navigate(`/customer/reply/${prevItem.reply_id}`);
    }
  };

  const goToNext = () => {
    if (nextItem) {
      navigate(`/customer/reply/${nextItem.reply_id}`);
    }
  };

  const deletePost = async () => {
    try {
      await axios.delete(
        `https://y-system.synology.me:8080/reply/delete/${id}`
      );
      navigate("/customer");
      alert("게시물이 삭제되었습니다.");
    } catch (error) {
      console.error("글 지우기에 실패하였습니다.:", error);
    }
  };

  const fileArray = replyItem.reply_file.split(",");

  const isImage = (filename) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    return imageExtensions.some((extension) =>
      filename.toLowerCase().endsWith(extension)
    );
  };

  return (
    <>
      <div className={customerStyles.customer}>
        {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
        <div className={customerStyles.customerTop}>
          <h2>CUSTOMER</h2>
          <h1>고객지원</h1>
        </div>
        <div className={customerStyles.customerFlex}>
          <Link
            className={isActive === "기술지원" ? customerStyles.active : ""}
            onClick={() => handleLinkClick("기술지원")}
          >
            기술지원
          </Link>
          <Link
            className={isActive === "문의하기" ? customerStyles.active : ""}
            onClick={() => handleLinkClick("문의하기")}
          >
            문의하기
          </Link>
        </div>
        <div className={downloadStyles.downDetail}>
          <h1>기술지원</h1>
          <div className={downloadStyles.downDetailTitle}>
            <h1>{replyItem.reply_title}</h1>
          </div>
          <div className={downloadStyles.downDetailFlex}>
            <div className={downloadStyles.dataTxt}>
              <h3>작성자</h3>
              <p>{replyItem.reply_name}</p>
            </div>
            <div className={downloadStyles.dataTxt}>
              <h3>작성일</h3>
              <p>{replyItem.reply_date}</p>
            </div>
            <div className={downloadStyles.dataTxt}>
              <h3>조회</h3>
              <p>{replyItem.reply_view}</p>
            </div>
          </div>
          <div className={downloadStyles.contentBox}>
            <p>{replyItem.reply_content}</p>
            {fileArray.map((file, index) =>
              isImage(file) ? (
                <img
                  key={index}
                  src={`https://y-system.synology.me:8080/reply/${file}`}
                  alt={`${reply.reply_title} - ${index + 1}`}
                />
              ) : null
            )}
          </div>
          <div className={downloadStyles.fileBox}>
            <h3>첨부파일</h3>
            {fileArray.map((file, index) => (
              <a
                key={index}
                href={`https://y-system.synology.me:8080/reply/${file}`}
                download
              >
                첨부파일{index}-{file.slice(0, 15)}...
                <img
                  src={downBtn}
                  alt="다운로드 버튼"
                  className={downloadStyles.downBtn}
                />
              </a>
            ))}
          </div>
          <div className={downloadStyles.btnBox}>
            <div className={downloadStyles.detailBtn}>
              <button onClick={goToPrev} disabled={!prevItem}>
                이전
              </button>
              <button onClick={listBtn}>목록</button>
              <button onClick={goToNext} disabled={!nextItem}>
                다음&#62;
              </button>
            </div>
            {isAuthenticated && ( // 로그인 상태일 때만 글 수정/삭제 버튼 보이기
              <div className={downloadStyles.txtBtn}>
                {/* <button>글수정</button> */}
                <button onClick={deletePost}>글삭제</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReplyDetail;
