import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "../../src/assets/swiper/styles.css";
import { Mousewheel, Pagination } from "swiper/modules";
import Main1 from "../pages/main/Main1";
import Main2 from "../pages/main/Main2";
import Main3 from "../pages/main/Main3";
import Main4 from "../pages/main/Main4";
import Main5 from "../pages/main/Main5";
import Main6 from "../pages/main/Main6";
import Main8 from "../pages/main/Main8";
import Main7 from "../pages/main/Main7";

const Main = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        mousewheel={true}
        pagination={{
          clickable: true,
        }}
        modules={[Mousewheel, Pagination]}
        className="mainSwiper"
        onSlideChange={handleSlideChange}
      >
        <SwiperSlide>
          <Main1 />
        </SwiperSlide>
        <SwiperSlide>
          <Main2 isActive={activeIndex === 1} />
        </SwiperSlide>
        <SwiperSlide>
          <Main3 isActive={activeIndex === 2} />
        </SwiperSlide>
        <SwiperSlide>
          <Main4 isActive={activeIndex === 3} />
        </SwiperSlide>
        <SwiperSlide>
          <Main5 isActive={activeIndex === 4} />
        </SwiperSlide>
        <SwiperSlide>
          <Main6 isActive={activeIndex === 5} />
        </SwiperSlide>
        <SwiperSlide>
          <Main7 isActive={activeIndex === 6} />
        </SwiperSlide>
        <SwiperSlide>
          <Main8 isActive={activeIndex === 7} />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Main;
