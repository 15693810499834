import React from "react";
import companyStyles from "../../assets/pagesStyle/intro/company.module.css";
import bottomBg from "../../assets/images/bottomBg.png";
import useCountUp from "../../assets/hooks/useCountUp";
const CompanyBottom = () => {
  const sales = useCountUp(10, 500);
  return (
    <div className={companyStyles.companyBottom}>
      <div className={companyStyles.txtBox}>
        <h1>세계로 나아가는 컴퍼니</h1>
        <p>
          첨단자동화솔루션을통해 산업의미래를혁신하고,
          지속가능한발전을추구합니다.
        </p>
        <img src={bottomBg} alt="company백그라운드 이미지" />
      </div>
      <div className={companyStyles.flex2}>
        <div className={companyStyles.numBox}>
          <h2>매출액</h2>
          <h3>{sales}억</h3>
        </div>
        <div className={companyStyles.numBox}>
          <h2>프로젝트</h2>
          <h3>20건</h3>
        </div>
      </div>
    </div>
  );
};

export default CompanyBottom;
