import React, { useEffect, useRef, useState } from "react";
import companyStyles from "../../assets/pagesStyle/intro/company.module.css";

import Footer from "../footer/Footer";
import CompanyTop from "./CompanyTop";
import CompanyMid from "./CompanyMid";
import CompanyBottom from "./CompanyBottom";

const Company = () => {
  const topRef = useRef(null);
  const midRef = useRef(null);
  const bottomRef = useRef(null);

  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const topPosition = topRef.current.getBoundingClientRect().top;
      const midPosition = midRef.current.getBoundingClientRect().top;
      const bottomPosition = bottomRef.current.getBoundingClientRect().top;

      const windowHeight = window.innerHeight;

      if (topPosition < windowHeight && topPosition >= 0) {
        setActiveSection("top");
      } else if (midPosition < windowHeight && midPosition >= 0) {
        setActiveSection("mid");
      } else if (bottomPosition < windowHeight && bottomPosition >= 0) {
        setActiveSection("bottom");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={companyStyles.company}>
        <div
          ref={topRef}
          className={activeSection === "top" ? companyStyles.active : ""}
        >
          <CompanyTop />
        </div>
        <div
          ref={midRef}
          className={activeSection === "mid" ? companyStyles.active : ""}
        >
          <CompanyMid />
        </div>
        <div
          ref={bottomRef}
          className={activeSection === "bottom" ? companyStyles.active : ""}
        >
          <CompanyBottom />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Company;
