import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { Link, useLocation } from "react-router-dom";
import productStyles from "../assets/pagesStyle/product/product.module.css";
import Business from "../pages/product/Business";
import Technology from "../pages/product/Technology";
import MobileNav from "./MobileNav";

const Product = () => {
  const [isActive, setIsActive] = useState("Business");
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);
  const handleLinkClick = (link) => {
    setIsActive(link);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={productStyles.product}>
      {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
      <div className={productStyles.productTop}>
        <h2>product description</h2>
        <h1>제품소개</h1>
      </div>
      <div className={productStyles.productFlex}>
        <Link
          className={isActive === "Business" ? productStyles.active : ""}
          onClick={() => handleLinkClick("Business")}
        >
          Business
        </Link>
        {/* <Link
          className={isActive === "Technology" ? productStyles.active : ""}
          onClick={() => handleLinkClick("Technology")}
        >
          Technology
        </Link> */}
      </div>
      <div className={productStyles.pages}>
        {isActive === "Business" && <Business />}
        {isActive === "Technology" && <Technology />}
      </div>
    </div>
  );
};

export default Product;
