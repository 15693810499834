import React from "react";
import noticeStyles from "../../assets/pagesStyle/recruit/notice.module.css";
import Footer from "../footer/Footer";
import recruitData from "../../data/recruitData";
import linkArrow from "../../assets/images/linkArrow.svg";

const Notice = () => {
  return (
    <>
      <div className={noticeStyles.notice}>
        <div className={noticeStyles.noticeBox}>
          <div className={noticeStyles.txtBox}>
            <h1>채용공고</h1>
            <p>함께 만들어가는 성과를 통해 변하지 않는 가치를 실현합니다.</p>
          </div>
          <div className={noticeStyles.links}>
            {recruitData.map((item, i) => (
              <div key={item.id} className={noticeStyles.linkBox}>
                <p>{item.date}</p>
                <h2>{item.title}</h2>
                <a href={item.link} target="_blank" rel="noreferrer">
                  <img src={linkArrow} alt={linkArrow} />
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Notice;
