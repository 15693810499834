import React from "react";
import footerMainStyles from "../../assets/pagesStyle/footer/footerMain.module.css";
import logo from "../../assets/images/footerLogo.png";
import sns1 from "../../assets/images/instargram.png";
import sns2 from "../../assets/images/facebook.png";
import sns3 from "../../assets/images/youtube.png";
import sns4 from "../../assets/images/kakaotalk.png";

const FooterMain = ({ className }) => {
  return (
    <>
      <div className={`${footerMainStyles.footerMain} ${className}`}>
        <div className={footerMainStyles.footerMainBox}>
          <div className={footerMainStyles.logos}>
            <img src={logo} alt="footerLogo" />
            <div className={footerMainStyles.sns}>
              <img src={sns1} alt="instargram" />
              <img src={sns2} alt="facebook" />
              <img src={sns3} alt="youtube" />
              <img src={sns4} alt="kakaotalk" />
            </div>
          </div>
          <div className={footerMainStyles.info}>
            <h1>와이시스템즈</h1>
            <div className={footerMainStyles.txtBox}>
              <p>대표 : 최치영</p>
              <p>사업자등록번호 : 643-86-02978</p>
              <p>주소 : 평택시 고덕중앙로 290, 9층 27호</p>
            </div>
          </div>
          <div className={footerMainStyles.contact}>
            <h1>contact</h1>
            <div className={footerMainStyles.txtBox}>
              <p>TEL : 010 2528 9281</p>
              <p>Email : cychoi@y-system.co.kr</p>
            </div>
          </div>
        </div>
        <div className={footerMainStyles.copyright}>
          <h3>
            COPYRIGHT © <strong> Ysystems</strong> All Rights Reserved.
          </h3>
        </div>
      </div>
    </>
  );
};

export default FooterMain;
