import React, { useEffect, useState } from "react";
import axios from "axios";
import askStyles from "../../assets/pagesStyle/customer/ask.module.css";
import customerStyles from "../../assets/pagesStyle/customer/customer.module.css";
import Footer from "../footer/Footer";
import Nav from "../../components/Nav";
import { Link, useLocation, useNavigate } from "react-router-dom";

const DownloadUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("자료실");

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await axios.post(
        "https://y-system.synology.me:8080/download",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("게시물 작성이 완료되었습니다.");
        navigate("/promote", { state: { activeTab: "자료실" } });
      } else {
        alert("제출 중 오류가 발생했습니다.");
      }
    } catch (error) {
      alert("제출 중 오류가 발생했습니다.");
    }
  };

  return (
    <>
      <div className={customerStyles.customer}>
        <Nav isActive={true} />
        <div className={customerStyles.customerTop}>
          <h2>Admin</h2>
          <h1>자료실</h1>
        </div>
        <div className={customerStyles.customerFlex}>
          <Link
            className={isActive === "자료실" ? customerStyles.active : ""}
            onClick={() => handleLinkClick("자료실")}
          >
            자료실
          </Link>
        </div>
        <div className={askStyles.ask}>
          <div className={askStyles.askBox}>
            <div className={askStyles.txtBox2}>
              <div className={askStyles.txt}>
                <h1>자료실</h1>
                <p>자료실입니다.</p>
              </div>
            </div>
            <div className={askStyles.formBox2}>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="https://y-system.synology.me:8080/download"
                enctype="multipart/form-data"
              >
                <div className={askStyles.formTxt}>
                  <input
                    type="text"
                    id="name"
                    name="download_name"
                    required
                    value="ysystems"
                  />
                </div>
                <div className={askStyles.formTxt}>
                  <input
                    type="text"
                    id="name"
                    name="download_title"
                    required
                    placeholder="제목"
                  />
                </div>
                <div className={askStyles.formFile}>
                  <input type="file" id="file" name="download_file" multiple />
                </div>
                <div className={askStyles.formMsg}>
                  <textarea
                    name="download_content"
                    id="ask"
                    placeholder="기술지원이 필요하신 내용을 남겨주세요."
                  ></textarea>
                </div>

                <div className={askStyles.formSubmit}>
                  <button type="submit">작성하기</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default DownloadUpload;
