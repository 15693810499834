import React from "react";
import companyStyles from "../../assets/pagesStyle/intro/company.module.css";
import tech from "../../assets/images/tech.png";
import honest from "../../assets/images/honest.png";
import trust from "../../assets/images/trust.png";
const CompanyMid = () => {
  return (
    <div className={companyStyles.companyMidBg}>
      <div className={companyStyles.companyMid}>
        <div className={companyStyles.txtBox}>
          <h1>비전</h1>
          <p>
            와이시스템즈는 탁월한 기술력을 통해, 디지털 신기술 분야에서 새로운
            글로벌 리더가 되기를 꿈꿉니다.
            <br /> 항상 고객을 위해 해야 할 일을 고민하고, 발견하며, 효율적인
            소통과 믿음직스러운 서비스를 실현하는 솔루션을 만들어 갑니다.
          </p>
        </div>
        <div className={companyStyles.flex2}>
          <div className={companyStyles.imgBox}>
            <img src={tech} alt="기술이미지" />
            <h2>기술력</h2>
          </div>
          <div className={companyStyles.imgBox}>
            <img src={honest} alt="정직이미지" />
            <h2>정직</h2>
          </div>
          <div className={companyStyles.imgBox}>
            <img src={trust} alt="신뢰이미지" />
            <h2>신뢰</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyMid;
