import React, { useEffect, useState } from "react";
import techStyles from "../../assets/pagesStyle/customer/techsupport.module.css";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import { BsArrowReturnRight } from "react-icons/bs";
import axios from "axios";
import Loading from "../loading/Loading";
import useAuth from "../../assets/hooks/useAuth";

const Techsupport = () => {
  const [reply, setReply] = useState([]);
  const [tech, setTech] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true); // Added loading state
  const itemsPerPage = 10;
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기
  const totalPages = Math.ceil(tech.length / itemsPerPage);

  const handleClick = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const currentItems = tech.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  useEffect(() => {
    async function getReply() {
      try {
        const response = await axios.get(
          "https://y-system.synology.me:8080/reply"
        );
        const data = response.data;

        setReply(data);
      } catch (error) {
        console.error("답글 데이터를 불러올 수 없습니다!:", error);
      }
    }
    getReply();
  }, []);

  useEffect(() => {
    async function getTech() {
      try {
        const response = await axios.get(
          "https://y-system.synology.me:8080/support"
        );
        const data = response.data;
        const sortedData = data.sort(
          (a, b) => new Date(b.tech_id) - new Date(a.tech_id)
        );
        setTech(sortedData);
      } catch (error) {
        console.error("기술 지원 데이터를 불러올 수 없습니다!:", error);
      } finally {
        setLoading(false);
      }
    }
    getTech();
  }, []);

  return (
    <>
      <div className={techStyles.tech}>
        <div className={techStyles.techBox}>
          <div className={techStyles.txtBox}>
            <h1>기술지원</h1>
            <p>내용을 입력하세요.</p>
          </div>
          <div className={techStyles.supportBox}>
            <div className={techStyles.supportTop}>
              <h2>번호</h2>
              <h2>제목</h2>
              <h2>작성일</h2>
            </div>
            <div className={`${techStyles.lengthBox}`}>
              <h2>
                전체 <span></span>
                {tech.length}
              </h2>
            </div>
            {loading ? (
              <Loading />
            ) : currentItems.length === 0 ? (
              <div className={techStyles.support}>
                <p className={techStyles.supportNum}>test</p>
                <p className={techStyles.supportTitle}>
                  <p>현재 등록 된 게시물이 없습니다.</p>
                </p>
                <p>2099</p>
              </div>
            ) : (
              <div>
                {currentItems.map((item) => (
                  <div key={item.tech_id}>
                    {isAuthenticated && (
                      <Link
                        to={`/admin/reply/${item.tech_id}`}
                        className={techStyles.replyBtn}
                      >
                        답글달기
                      </Link>
                    )}
                    <div className={techStyles.support}>
                      <p className={techStyles.supportNum}>{item.tech_id}</p>

                      <Link to={`/customer/support/${item.tech_id}`}>
                        <p className={techStyles.supportTitle}>
                          {item.tech_title}
                        </p>
                      </Link>
                      <div className={techStyles.pBox}>
                        <p className={`${techStyles.techName}`}>
                          {item.tech_name}
                          <span>l</span>
                        </p>
                        <p>{item.tech_date} </p>
                        <p className={`${techStyles.techView}`}>
                          조회
                          <span></span>
                          {item.download_view}
                        </p>
                      </div>
                    </div>
                    {reply
                      .filter((rep) => rep.tech_id === item.tech_id)
                      .map((rep) => (
                        <div className={techStyles.replies} key={rep.reply_id}>
                          <div className={techStyles.reply}>
                            <p className={techStyles.supportNum}>
                              <BsArrowReturnRight />
                            </p>
                            <Link
                              to={`/customer/support/reply/${rep.reply_id}`}
                            >
                              <p className={techStyles.supportTitle}>
                                {rep.reply_title}
                              </p>
                            </Link>
                            <p className={techStyles.supportDate}>
                              {rep.reply_date}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            )}
          </div>
          <Link className={techStyles.txtBtn} to="/customer/support/ask">
            글쓰기
          </Link>
        </div>
        <div className={techStyles.pagination}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handleClick(i + 1)}
              className={currentPage === i + 1 ? techStyles.activePage : ""}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Techsupport;
