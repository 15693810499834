import React from "react";
import "./App.css";
import "../src/assets/font/font.css";
// 폰트css
import "../src/assets/setting/reset.scss";
// 리셋css
import Main from "./components/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Intro from "./components/Intro";
import Customer from "./components/Customer";
import Product from "./components/Product";
import Promote from "./components/Promote";
import Recruit from "./components/Recruit";
import NewsDetail from "./components/NewsDetail";
import DownDetail from "./components/DownDeatail";
import TechDetail from "./components/TechDetail";
import ReplyDetail from "./components/ReplyDetails";
import TechAsk from "./pages/customer/TechAsk";
import Admin from "./components/Admin";
import Reply from "./pages/admin/Reply";
import NewsAdmin from "./pages/admin/NewsAdmin";
import DownloadUpload from "./pages/promote/DownloadUpload";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/intro" element={<Intro />} />
        <Route path="/customer" element={<Customer />} />
        <Route path="/product" element={<Product />} />
        <Route path="/promote" element={<Promote />} />
        <Route path="/recruit" element={<Recruit />} />
        {/* 뉴스 id 마다 생성되는 링크 */}
        <Route path="/news/:id" element={<NewsDetail />} />
        {/* 다운로드 id 마다 생성되는 링크 */}
        <Route path="/promote/download/:id" element={<DownDetail />} />
        <Route path="/promote/download/upload" element={<DownloadUpload />} />

        {/* 기술지원 게시물 id 마다 생성되는 링크 */}
        <Route path="/customer/support/:id" element={<TechDetail />} />
        <Route path="/customer/support/reply/:id" element={<ReplyDetail />} />
        {/* 글쓰기 페이지 - 작성 후 기술지원으로 넘어가게 */}
        <Route path="/customer/support/ask" element={<TechAsk />} />
        {/* 어드민 페이지 */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/admin/reply/:techId" element={<Reply />} />
        <Route path="/admin/news" element={<NewsAdmin />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
