import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { Link } from "react-router-dom";
import promoteStyles from "../assets/pagesStyle/promote/promote.module.css";
import News from "../pages/promote/News";
import Download from "../pages/promote/Download";
import MobileNav from "./MobileNav";

const Promote = () => {
  const [isActive, setIsActive] = useState("News");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const handleLinkClick = (link) => {
    setIsActive(link);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={promoteStyles.promote}>
      {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
      <div className={promoteStyles.promoteTop}>
        <h2>promote</h2>
        <h1>홍보센터</h1>
      </div>
      <div className={promoteStyles.promoteFlex}>
        <Link
          className={isActive === "News" ? promoteStyles.active : ""}
          onClick={() => handleLinkClick("News")}
        >
          새소식
        </Link>
        <Link
          className={isActive === "Download" ? promoteStyles.active : ""}
          onClick={() => handleLinkClick("Download")}
        >
          자료실
        </Link>
      </div>
      <div className={promoteStyles.pages}>
        {isActive === "News" && <News />}
        {isActive === "Download" && <Download />}
      </div>
    </div>
  );
};

export default Promote;
