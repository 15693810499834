import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { Link, useLocation } from "react-router-dom";
import recruitStyles from "../assets/pagesStyle/recruit/recruit.module.css";
import Info from "../pages/recruit/Info";
import Notice from "../pages/recruit/Notice";
import MobileNav from "./MobileNav";

const Recruit = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState("Info");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);
  const handleLinkClick = (link) => {
    setIsActive(link);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={recruitStyles.recruit}>
      {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
      <div className={recruitStyles.recruitTop}>
        <h2>Recruitment of talent</h2>
        <h1>인재채용</h1>
      </div>
      <div className={recruitStyles.recruitFlex}>
        <Link
          className={isActive === "Info" ? recruitStyles.active : ""}
          onClick={() => handleLinkClick("Info")}
        >
          채용정보
        </Link>
        <Link
          className={isActive === "Notice" ? recruitStyles.active : ""}
          onClick={() => handleLinkClick("Notice")}
        >
          채용공고
        </Link>
      </div>
      <div className={recruitStyles.pages}>
        {isActive === "Info" && <Info />}
        {isActive === "Notice" && <Notice />}
      </div>
    </div>
  );
};

export default Recruit;
