import React from "react";
import managementStyles from "../../assets/pagesStyle/intro/management.module.css";
import Footer from "../footer/Footer";
import managementBg1 from "../../assets/images/managementBg1.png";
import managementBg2 from "../../assets/images/managementBg2.png";
import managementBg3 from "../../assets/images/managementBg3.mp4";
import { Link } from "react-router-dom";

const Management = () => {
  return (
    <>
      <div className={managementStyles.management}>
        <div className={managementStyles.managementBox}>
          <div className={managementStyles.managementTop}>
            <div className={managementStyles.flex}>
              <div className={managementStyles.txtBox}>
                <h2>Our services 01</h2>
                <h1>지속적인 기술 혁신</h1>
              </div>

              <div className={managementStyles.txtBox2}>
                <p>
                  사람 중심의 혁신을 지향합니다. 직원의 성장은 회사의 발전과
                  직결되며,
                  <br /> 우리는 모든 구성원이 자신의 잠재력을 최대한 발휘할 수
                  있는 환경을 제공합니다.
                </p>
              </div>
            </div>
            <img src={managementBg1} alt="managementBg1" />
          </div>
          <div className={managementStyles.line}></div>
          <div className={managementStyles.managementMid}>
            <div className={managementStyles.flex}>
              <div className={managementStyles.txtBox}>
                <h2>Our services 02</h2>
                <h1>전략적 파트너십</h1>
              </div>

              <div className={managementStyles.txtBox2}>
                <p>
                  사람 중심의 혁신을 지향합니다. 직원의 성장은 회사의 발전과
                  직결되며,
                  <br /> 우리는 모든 구성원이 자신의 잠재력을 최대한 발휘할 수
                  있는 환경을 제공합니다.
                </p>
              </div>
            </div>
            <img src={managementBg2} alt="managementBg1" />
          </div>
        </div>
        <div className={managementStyles.managementBottom}>
          <video
            autoPlay
            loop
            muted
            playsInline
            className={managementStyles.video}
          >
            <source src={managementBg3} type="video/mp4" />
          </video>
          <div className={managementStyles.flex2}>
            <div className={managementStyles.txtBox}>
              <h1>
                Beyond
                <br />
                Technology
              </h1>
            </div>

            <div className={managementStyles.txtBox2}>
              <p>
                고객의 차별화된 가치 창출을 위해 끊임없이 혁신하고,
                <br /> 최고의 기술로 한 걸음 앞서가기 위해 계속 <br />
                변화해나가겠습니다.
              </p>
              <Link to="/customer">문의하기</Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Management;
