import React, { useEffect, useRef } from "react";
import infoStyles from "../../assets/pagesStyle/recruit/info.module.css";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import infoBg3 from "../../assets/images/managementBg3.mp4";
import img1 from "../../assets/images/infoIcon1.svg";
import img2 from "../../assets/images/infoIcon2.svg";
import img3 from "../../assets/images/infoIcon3.svg";
import icon1 from "../../assets/images/info1.svg";
import icon2 from "../../assets/images/info2.svg";
import icon3 from "../../assets/images/info3.svg";
import icon4 from "../../assets/images/info4.svg";

const Info = () => {
  const topRef = useRef(null);
  const midRef = useRef(null);
  const bottomRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const topElement = topRef.current;
      const midElement = midRef.current;
      const bottomElement = bottomRef.current;

      if (topElement) {
        const topRect = topElement.getBoundingClientRect();
        if (topRect.top <= window.innerHeight && topRect.bottom >= 0) {
          topElement.classList.add(infoStyles.animated);
        } else {
          topElement.classList.remove(infoStyles.animated);
        }
      }

      if (midElement) {
        const midRect = midElement.getBoundingClientRect();
        if (midRect.top <= window.innerHeight && midRect.bottom >= 0) {
          midElement.classList.add(infoStyles.animated);
        } else {
          midElement.classList.remove(infoStyles.animated);
        }
      }

      if (bottomElement) {
        const bottomRect = bottomElement.getBoundingClientRect();
        if (bottomRect.top <= window.innerHeight && bottomRect.bottom >= 0) {
          bottomElement.classList.add(infoStyles.animated);
        } else {
          bottomElement.classList.remove(infoStyles.animated);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={infoStyles.info}>
        <div className={infoStyles.infoBox}>
          <div className={infoStyles.txtTop}>
            <h1>인재상</h1>
            <p>
              사람 중심의 혁신을 지향합니다. 직원의 성장은 회사의 발전과
              직결되며,
              <br /> 우리는 모든 구성원이 자신의 잠재력을 최대한 발휘할 수 있는
              환경을 제공합니다.
            </p>
          </div>
        </div>
        <div ref={topRef} className={infoStyles.infoTop}>
          <div className={infoStyles.infoTopBox}>
            <div className={infoStyles.infoBox1}>
              <div className={infoStyles.infoImgBox}>
                <img src={img1} alt="infoIcon1" />
                <h2>열정</h2>
              </div>
              <div className={infoStyles.txtBox}>
                <h2>열정과 도전 정신</h2>
                <p>
                  항상 새로운 것을 시도하며, 차별화된 제품과 서비스를 제공하기
                  위해 끊임없이 도전합니다.
                  <br /> 같은 열정과 도전 정신을 가진 인재와 함께 성장하고
                  발전할 수 있다고 믿습니다.
                </p>
              </div>
            </div>
            <div className={infoStyles.infoBox2}>
              <div className={infoStyles.infoImgBox}>
                <img src={img2} alt="infoIcon2" />
                <h2>협력</h2>
              </div>
              <div className={infoStyles.txtBox}>
                <h2>협력과 소통 능력</h2>
                <p>
                  팀워크를 중요하게 생각하며, 서로가 소통하고 협력하는 문화를
                  만들어갑니다.
                  <br />
                  같은 목표를 향해 함께 노력하며, 서로가 배울 수 있는 기회를
                  만들어가는 인재를 찾고 있습니다.
                </p>
              </div>
            </div>
            <div className={infoStyles.infoBox3}>
              <div className={infoStyles.infoImgBox}>
                <img src={img3} alt="infoIcon3" />
                <h2>창의력</h2>
              </div>
              <div className={infoStyles.txtBox}>
                <h2>창의적 사고와 문제 해결 능력</h2>
                <p>
                  항상 새로운 아이디어를 충분히 검토하고, 적용 가능한 것들을
                  선별합니다.
                  <br />
                  또한, 문제가 발생했을 때 빠르게 대처하며 해결책을 찾는 능력을
                  가진 인재와
                  <br />
                  함께 미래를 열어가고자 합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div ref={midRef} className={infoStyles.infoMid}>
          <div className={infoStyles.txtBox}>
            <h1>채용절차</h1>
            <p>
              철저하고 공정한 채용절차를 통해, 열정적이면서도 역량 있는 인재를
              찾고 있습니다
            </p>
          </div>
          <div className={infoStyles.infoMidBox}>
            <div className={infoStyles.infoMidItem}>
              <img src={icon1} alt={icon1} />
              <div className={infoStyles.infoMidItemTxt}>
                <h2>01.원서접수</h2>
                <p>
                  구직사이트 통해
                  <br />
                  원서를 접수합니다.
                </p>
              </div>
            </div>
            <div className={infoStyles.infoMidItem}>
              <img src={icon2} alt={icon2} />
              <div className={infoStyles.infoMidItemTxt}>
                <h2>02.서류전형</h2>
                <p>
                  자기소개서를 통해 1차적인
                  <br />
                  역량 검증을 진행합니다.
                </p>
              </div>
            </div>
            <div className={infoStyles.infoMidItem}>
              <img src={icon3} alt={icon3} />
              <div className={infoStyles.infoMidItemTxt}>
                <h2>03.면접전형</h2>
                <p>
                  개인역량에 대해 심층
                  <br />
                  검증하는 단계입니다.
                </p>
              </div>
            </div>
            <div className={infoStyles.infoMidItem}>
              <img src={icon4} alt={icon4} />
              <div className={infoStyles.infoMidItemTxt}>
                <h2>04.최종합격</h2>
                <p>
                  최종합격 후 지원자에게
                  <br />
                  합격통보를 합니다.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div ref={bottomRef} className={infoStyles.infoBottom}>
          <video autoPlay loop muted playsInline className={infoStyles.video}>
            <source src={infoBg3} type="video/mp4" />
          </video>
          <div className={infoStyles.flex2}>
            <div className={infoStyles.txtBox}>
              <h1>
                Beyond
                <br />
                Technology
              </h1>
            </div>

            <div className={infoStyles.txtBox2}>
              <p>
                고객의 차별화된 가치 창출을 위해 끊임없이 혁신하고,
                <br /> 최고의 기술로 한 걸음 앞서가기 위해 계속 <br />
                변화해나가겠습니다.
              </p>
              <Link to="/customer">문의하기</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Info;
