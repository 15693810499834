import React, { useEffect } from "react";
import businessStyles from "../../assets/pagesStyle/product/business.module.css";
import productData from "../../data/productData";
import Footer from "../footer/Footer";

const Business = () => {
  useEffect(() => {
    const handleScroll = () => {
      const gridBoxes = document.querySelectorAll(`.${businessStyles.gridBox}`);
      gridBoxes.forEach((box) => {
        const rect = box.getBoundingClientRect();
        if (rect.top <= window.innerHeight && rect.bottom >= 0) {
          box.classList.add(businessStyles.active);
        } else {
          box.classList.remove(businessStyles.active);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className={businessStyles.business}>
        <div className={businessStyles.businessBox}>
          <div className={businessStyles.txtBox}>
            <h1>Business</h1>
            <p>
              반도체 기계설비, 2차전지 기계설비, 머신 비전 솔루션 등
              와이시스템즈를 대표하는 주요 제품군입니다.
              <br />
              모든 제품은 고객사 사양에 맞게 제작 가능합니다.
            </p>
          </div>
          <div className={businessStyles.grid}>
            {productData.map((item, id) => (
              <div key={id} className={businessStyles.gridBox}>
                <div className={businessStyles.imgBox}>
                  <img src={item.img} alt={item.title} />
                </div>
                <h2>{item.title}</h2>
                <p>{item.info}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Business;
