import historyBg1 from "../assets/images/historyBg1.png";
import historyBg2 from "../assets/images/historyBg2.png";
import historyBg3 from "../assets/images/historyBg3.png";

const historyData = [
  {
    id: 1,
    image: historyBg1,
    title: "2021년\n첨단자동화 솔루션 개발",
    items: ["누적 매출액 10억원 돌파"],
  },
  {
    id: 2,
    image: historyBg2,
    title: "2022년\n새로운 기술 도입",
    items: ["연구개발 투자 확대", "글로벌 파트너십 체결", "제품 라인업 확장"],
  },
  {
    id: 3,
    image: historyBg3,
    title: "2023년\n글로벌 시장 진출",
    items: ["해외 지사 설립", "국제 인증 획득", "매출 200억원 돌파"],
  },
];

export default historyData;
