import React from "react";
import ceoStyles from "../../assets/pagesStyle/intro/ceo.module.css";
import ceoBg from "../../assets/images/beyond.png";
import Footer from "../footer/Footer";

const Ceo = () => {
  return (
    <>
      <div className={ceoStyles.ceo}>
        <div className={ceoStyles.ceoBox}>
          <div className={ceoStyles.txtBox}>
            <h1>CEO 인사말</h1>
            <img src={ceoBg} alt="ceoBg" />
          </div>
          <div className={ceoStyles.txtBox2}>
            <h1>
              기술의 미래를 선도하는
              <br />
              와이시스템즈
            </h1>
            <p className={ceoStyles.txtCeo}>
              안녕하십니까! ㈜ 와이시스템즈의 대표이사 최치영입니다.
              <br />
              <br /> 우리는 급변하는 세계에서 기술 발전의 가속화를 견인하며,
              삶의 질을 향상시키는 신규 기술과 미래를 선도하는 기술을 추구하는
              기업입니다.
              <br />
              <br />
              현대 산업의 심장부인 반도체와 2차전지 분야에서, 저희
              와이시스템즈는 자동화 설비의 혁신적인 제조업체로서 자리매김하고
              있습니다. 저희는 단순히 기계를 만드는 것을 넘어, 고객과 사회가
              요구하는 지속 가능하고 효율적인 솔루션을 제공합니다.
              <br />
              <br />
              우리의 강점은 고도의 기술력과 끊임없는 연구개발에 있습니다. 우리는
              시장이 요구하는 변화에 신속하게 대응하고, 지속적인 혁신을 통해
              업계의 기준을 높이고 있습니다. 이를 통해, 기업들이 더욱 효율적으로
              운영될 수 있도록 돕고 있습니다.
              <br />
              <br />
              하지만 기술 이상의 가치를 추구합니다. 저희 와이시스템즈는 기본기를
              충실히 다지며, 사람 중심의 가치를 중요시합니다. 우리가 만드는 모든
              자동화 설비는 사용자와 환경을 먼저 생각합니다. 왜냐하면, 기술의
              진보는 결국 사람을 위한 것이기 때문입니다.
              <br />
              <br />
              우리는 고객과의 상생과 상호작용을 통해 바람직한 시스템을 구축하고,
              이를 통해 사회 전반에 긍정적인 영향을 미치고자 합니다.
              와이시스템즈와 함께라면, 여러분의 사업이 미래를 향해 한 걸음 더
              나아갈 수 있습니다.
              <br />
              <br />
              미래를 함께할 파트너로서, 와이시스템즈가 여러분의 사업에 가치를
              더하고 성장을 가속화하는 동반자가 되길 기대합니다.
              <br />
              <br />
              <p className={ceoStyles.txt}>감사합니다.</p>
              <p>
                <em>YSYSTEM 대표</em> 최치영
              </p>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ceo;
