import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import customerStyles from "../assets/pagesStyle/customer/customer.module.css";
import News from "../pages/promote/News";
import Download from "../pages/promote/Download";
import useAuth from "../assets/hooks/useAuth"; // useAuth import
import Login from "../assets/hooks/Login";
import Techsupport from "../pages/customer/Techsupport";

const Admin = () => {
  const location = useLocation();
  const [isActive, setIsActive] = useState("뉴스");
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  // 로그인하지 않은 경우 로그인 페이지로 리디렉션
  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <div className={customerStyles.customer}>
      <div className={customerStyles.customerTop}>
        <h2>Admin</h2>
        <h1>관리자 페이지 입니다.</h1>
      </div>
      <div className={customerStyles.customerFlex}>
        <Link
          className={isActive === "뉴스" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("뉴스")}
        >
          뉴스
        </Link>
        <Link
          className={isActive === "자료실" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("자료실")}
        >
          자료실
        </Link>
        <Link
          className={isActive === "기술지원" ? customerStyles.active : ""}
          onClick={() => handleLinkClick("기술지원")}
        >
          기술지원
        </Link>
      </div>

      <div className={customerStyles.pages}>
        {isActive === "뉴스" && <News />}
        {isActive === "자료실" && <Download />}
        {isActive === "기술지원" && <Techsupport />}
      </div>
    </div>
  );
};

export default Admin;
