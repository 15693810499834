import React, { useEffect, useRef } from "react";
import Nav from "../../components/Nav";
import main4Styles from "../../assets/pagesStyle/main/main4.module.css";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import "swiper/css/navigation";

import downArrow from "../../assets/images/downArrow.svg";
import productData from "../../data/productData"; // productData를 불러옵니다.

// import required modules
import { Pagination, Autoplay, Scrollbar, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Main4 = ({ isActive }) => {
  const main4Ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      // 스와이퍼 슬라이드가 활성화되었을 때 애니메이션 효과를 부여
      main4Ref.current.classList.add(main4Styles.active);
    } else {
      // 비활성화 시 클래스 제거
      main4Ref.current.classList.remove(main4Styles.active);
    }
  }, [isActive]);

  return (
    <>
      <Nav />
      <div ref={main4Ref} className={main4Styles.main4}>
        <div className={main4Styles.main4Flex}>
          <h1>와이컴퍼니즈는 다양한 솔루션을 제공합니다.</h1>
          <h2>
            제품소개에 대한 짧은 설명글을 써주세요.
            <br /> 첨단자동화솔루션을 통해 산업의미래를혁신하고,
            지속가능한발전을추구합니다.
            <div className={main4Styles.btn}>
              {/* 클릭시 제품소개이동 */}
              <img
                src={downArrow}
                alt="downArrow"
                className={main4Styles.svg}
              />
            </div>
          </h2>
        </div>
        {/* 스와이퍼 부분 */}
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          scrollbar={true}
          breakpoints={{
            390: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay, Scrollbar, Navigation]}
          className="main4Swiper"
        >
          {productData.map((product) => (
            <SwiperSlide key={product.id} className={main4Styles.productBox}>
              <div className={main4Styles.products}>
                <img src={product.img} alt={`product${product.id}`} />
                <div className={main4Styles.txtBox}>
                  <h3>
                    {" "}
                    {product.title.length > 14
                      ? `${product.title.slice(0, 14)}...`
                      : product.title}
                  </h3>
                  <Link to="/product">자세히보기</Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Main4;
