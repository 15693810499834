import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import { Link, useNavigate } from "react-router-dom";
import promoteStyles from "../assets/pagesStyle/promote/promote.module.css";
import { useParams } from "react-router-dom";
import newsStyles from "../assets/pagesStyle/promote/news.module.css";
import Footer from "../pages/footer/Footer";
import axios from "axios";
import useAuth from "../assets/hooks/useAuth";
import MobileNav from "./MobileNav";

const NewsDetail = () => {
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("News");
  const { id } = useParams();
  const newsItem = news.find((item) => item.news_id === parseInt(id));
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    async function getNews() {
      try {
        const response = await axios.get(
          "https://y-system.synology.me:8080/news"
        );
        const data = response.data;
        setNews(data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
      }
    }
    getNews();
  }, []);

  if (!newsItem) {
    return <div>News item not found</div>;
  }

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  const backBtn = () => {
    navigate("/promote");
  };
  const fileArray = newsItem.news_file.split(",");
  const isImage = (filename) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    return imageExtensions.some((extension) =>
      filename.toLowerCase().endsWith(extension)
    );
  };
  const deletePost = async () => {
    try {
      await axios.delete(`https://y-system.synology.me:8080/news/delete/${id}`);
      navigate("/promote");
      alert("게시물이 삭제되었습니다.");
    } catch (error) {
      console.error("글 지우기에 실패하였습니다.:", error);
    }
  };

  return (
    <>
      <div className={promoteStyles.promote}>
        {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
        <div className={promoteStyles.promoteTop}>
          <h2>promote</h2>
          <h1>홍보센터</h1>
        </div>
        <div className={promoteStyles.promoteFlex}>
          <Link
            className={isActive === "News" ? promoteStyles.active : ""}
            onClick={() => handleLinkClick("News")}
          >
            새소식
          </Link>
          <Link>자료실</Link>
        </div>
        <div className={newsStyles.newsDetail}>
          {isAuthenticated && <button onClick={deletePost}>뉴스삭제</button>}
          <div className={newsStyles.newsDetailTitle}>
            <h1>{newsItem.news_title}</h1>
          </div>
          {fileArray.map((file, index) =>
            isImage(file) ? (
              <img
                key={index}
                src={`https://y-system.synology.me:8080/news/${file}`}
                alt={`${news.news_title} - ${index + 1}`}
              />
            ) : null
          )}
          <h1>{newsItem.news_title}</h1>
          <p>{newsItem.news_content}</p>
          <button onClick={backBtn}>&#5176; 목록보기</button>
        </div>

        <div className={newsStyles.newsDetail2}>
          <h1 className={newsStyles.newsDetail2Title}>Related Posts</h1>
          <div className={newsStyles.newsDetail2Flex}>
            {news.slice(0, 3).map((i, id) => (
              <div className={newsStyles.newsDetail2Box} key={i.news_id}>
                <Link to={`/news/${i.news_id}`}>
                  <img
                    src={`https://y-system.synology.me:8080/news/${i.news_file}`}
                    alt={`${news.news_title} - ${id + 1}`}
                  />
                </Link>
                <h1>{i.news_title}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsDetail;
