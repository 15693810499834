import React, { useState, useEffect } from "react";
import useAuth from "./useAuth";
import { Link, useNavigate } from "react-router-dom";
import customerStyles from "../pagesStyle/customer/customer.module.css";
import askStyles from "../pagesStyle/customer/ask.module.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState("로그인");
  const [error, setError] = useState(""); // 에러 상태 추가
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      // 로그인 성공 후 페이지 새로고침 및 리디렉션
      window.location.reload();
      navigate("/admin");
      alert("관리자 페이지 연결에 성공하였습니다.");
    }
  }, [isAuthenticated, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(""); // 폼 제출 시 에러 초기화
    const success = login(username, password);

    if (!success) {
      setError("아이디 또는 비밀번호가 올바르지 않습니다."); // 로그인 실패 시 에러 메시지 설정
    }
  };
  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  return (
    <>
      <div className={customerStyles.customer}>
        <div className={customerStyles.customerTop}>
          <h2>LOGIN</h2>
          <h1>와이시스템즈 관리자 페이지 로그인</h1>
        </div>
        <div className={customerStyles.customerFlex}>
          <Link
            className={isActive === "로그인" ? customerStyles.active : ""}
            onClick={() => handleLinkClick("로그인")}
          >
            로그인
          </Link>
        </div>
        <div className={askStyles.ask}>
          <div className={askStyles.askBox2}>
            <div className={askStyles.formBox2}>
              <h2>관리자 로그인</h2>
              <form onSubmit={handleSubmit}>
                <div className={askStyles.formTxt}>
                  <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder="아이디를 입력하세요."
                  />
                </div>
                <div className={askStyles.formTxt}>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="비밀번호를 입력하세요"
                  />
                </div>
                {error && <p style={{ color: "red" }}>{error}</p>}{" "}
                {/* 에러 메시지 표시 */}
                <div className={askStyles.formSubmit}>
                  <button type="submit">로그인</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
