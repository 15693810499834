import React, { useState, useEffect } from "react";
import Nav from "./Nav";
import introStyles from "../assets/pagesStyle/intro/intro.module.css";
import { Link } from "react-router-dom";
import Company from "../pages/intro/Company";
import Ceo from "../pages/intro/Ceo";
import Management from "../pages/intro/Management";
import History from "../pages/intro/History";
import MobileNav from "./MobileNav";

const Intro = () => {
  const [isActive, setIsActive] = useState("개요");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={introStyles.intro}>
      {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
      <div className={introStyles.introTop}>
        <h2>ABOUT US</h2>
        <h1>회사소개</h1>
      </div>
      <div className={introStyles.introFlex}>
        <Link
          className={isActive === "개요" ? introStyles.active : ""}
          onClick={() => handleLinkClick("개요")}
        >
          개요
        </Link>
        <Link
          className={isActive === "CEO 인사말" ? introStyles.active : ""}
          onClick={() => handleLinkClick("CEO 인사말")}
        >
          CEO 인사말
        </Link>
        <Link
          className={isActive === "경영 철학" ? introStyles.active : ""}
          onClick={() => handleLinkClick("경영 철학")}
        >
          경영 철학
        </Link>
        <Link
          className={isActive === "연혁" ? introStyles.active : ""}
          onClick={() => handleLinkClick("연혁")}
        >
          연혁
        </Link>
      </div>
      <div className={introStyles.pages}>
        {isActive === "개요" && <Company />}
        {isActive === "CEO 인사말" && <Ceo />}
        {isActive === "경영 철학" && <Management />}
        {isActive === "연혁" && <History />}
      </div>
    </div>
  );
};

export default Intro;
