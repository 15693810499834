import React, { useEffect, useState } from "react";
import downloadStyles from "../../assets/pagesStyle/promote/download.module.css";
import { Link } from "react-router-dom";
import Footer from "../footer/Footer";
import useAuth from "../../assets/hooks/useAuth";
import axios from "axios";

const Download = () => {
  const [download, setDownload] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Adjust this number based on how many items you want per page
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기
  const totalPages = Math.ceil(download.length / itemsPerPage);

  useEffect(() => {
    async function getDownload() {
      try {
        const response = await axios.get(
          "https://y-system.synology.me:8080/download"
        );
        const data = response.data;
        const sortedData = data.sort(
          (a, b) => new Date(b.download_id) - new Date(a.download_id)
        );
        setDownload(sortedData);
      } catch (error) {
        console.error("Error fetching replies:", error);
      }
    }
    getDownload();
  }, []);

  const handleClick = (pageNum) => {
    setCurrentPage(pageNum);
  };

  const currentItems = download.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  return (
    <>
      <div className={downloadStyles.down}>
        <div className={downloadStyles.downBox}>
          <div className={downloadStyles.txtBox}>
            <h1>자료실</h1>
            <p>필요한 제품 카다로그를 다운받으세요</p>
          </div>
          <div className={downloadStyles.supportBox}>
            <div className={downloadStyles.supportTop}>
              <h2>번호</h2>
              <h2>제목</h2>
              <h2>작성일</h2>
            </div>
            <div className={`${downloadStyles.lengthBox}`}>
              <h2>
                전체 <span></span>
                {download.length}
              </h2>
            </div>
            {currentItems.map((item, i) => (
              <div key={i} className={downloadStyles.support}>
                <p className={downloadStyles.supportNum}>{item.download_id}</p>
                <Link to={`/promote/download/${item.download_id}`}>
                  <p className={downloadStyles.supportTitle}>
                    {item.download_title}
                  </p>
                </Link>
                <div className={downloadStyles.pBox}>
                  <p className={`${downloadStyles.downloadName}`}>
                    {item.download_name}
                    <span>l</span>
                  </p>
                  <p>{item.download_date} </p>
                  <p className={`${downloadStyles.downloadView}`}>
                    조회
                    <span></span>
                    {item.download_view}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={downloadStyles.txtBtn}>
          {isAuthenticated && <Link to="/promote/download/upload">글쓰기</Link>}
        </div>
        <div className={downloadStyles.pagination}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => handleClick(i + 1)}
              className={currentPage === i + 1 ? downloadStyles.activePage : ""}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Download;
