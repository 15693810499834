import React, { useState } from "react";
import askStyles from "../../assets/pagesStyle/customer/ask.module.css";
import Footer from "../footer/Footer";
import address from "../../assets/images/address2.svg";
import phone from "../../assets/images/phone.svg";
import mail from "../../assets/images/mail.svg";
import axios from "axios";

const Ask = () => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://y-system.synology.me:8080/api/contact/send", formData)
      .then((response) => {
        alert("문의가 성공적으로 전송되었습니다.");
        setFormData({
          name: "",
          number: "",
          email: "",
          message: "",
        });
      })
      .catch((error) => {
        alert("문제 발생: " + error.message);
      });
  };

  return (
    <>
      <div className={askStyles.ask}>
        <div className={askStyles.askBox}>
          <div className={askStyles.txtBox}>
            <div className={askStyles.txt}>
              <h1>문의하기</h1>
              <p>
                문의하실 부분이 있으시면 내용을 남겨주세요.
                <br /> 빠른 시일 내에 연락 드리겠습니다.
              </p>
            </div>

            <div className={askStyles.svgBox}>
              <img src={address} alt="" />
              <p>평택시 고덕중앙로 290, 9층 27호</p>
            </div>
            <div className={askStyles.svgBox}>
              <img src={phone} alt="" />
              <p>010-2528-9281</p>
            </div>
            <div className={askStyles.svgBox}>
              <img src={mail} alt="" />
              <p>cychoi@y-system.co.kr</p>
            </div>
          </div>
          <div className={askStyles.formBox}>
            <form onSubmit={handleSubmit}>
              <div className={askStyles.formTxt}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  placeholder="이름"
                />
              </div>
              <div className={askStyles.formTxt}>
                <input
                  type="text"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  required
                  placeholder="연락처"
                />
              </div>
              <div className={askStyles.formTxt}>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder="이메일"
                />
              </div>
              <div className={askStyles.formMsg}>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="문의내용"
                ></textarea>
              </div>
              <div className={askStyles.formSubmit}>
                <button type="submit">문의하기</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Ask;
