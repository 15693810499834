import React, { useEffect, useState } from "react";
import axios from "axios";
import askStyles from "../../assets/pagesStyle/customer/ask.module.css";
import customerStyles from "../../assets/pagesStyle/customer/customer.module.css";
import Footer from "../footer/Footer";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Login from "../../assets/hooks/Login";
import useAuth from "../../assets/hooks/useAuth";

const Reply = () => {
  const { techId } = useParams(); // URL에서 techId 받아오기
  const location = useLocation();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("기술지원");
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await axios.post(
        "https://y-system.synology.me:8080/reply",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("게시물 작성이 완료되었습니다.");
        navigate("/admin", { state: { activeTab: "기술지원" } });
      } else {
        alert("제출 중 오류가 발생했습니다.");
      }
    } catch (error) {
      alert("제출 중 오류가 발생했습니다.");
    }
  };
  if (!isAuthenticated) {
    return <Login />;
  }

  const backBtn = () => {
    navigate("/admin", { state: { activeTab: "기술지원" } });
  };

  return (
    <>
      <div className={customerStyles.customer}>
        <div className={customerStyles.customerTop}>
          <h2>Admin</h2>
          <h1>답글 작성</h1>
        </div>
        <div className={customerStyles.customerFlex}>
          <Link
            className={isActive === "기술지원" ? customerStyles.active : ""}
            onClick={() => handleLinkClick("기술지원")}
          >
            답글작성
          </Link>
        </div>
        <div className={askStyles.ask}>
          <div className={askStyles.askBox}>
            <div className={askStyles.txtBox2}>
              <div className={askStyles.txt}>
                <h1>답글 작성</h1>
                <p>
                  답글을
                  <br /> 작성하는 페이지 입니다.
                </p>
                <button onClick={backBtn}>← 뒤로가기</button>
              </div>
            </div>
            <div className={askStyles.formBox2}>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="https://y-system.synology.me:8080/reply"
                enctype="multipart/form-data"
              >
                <h2>{techId}번 게시물에 대한 답글을 작성합니다.</h2>
                <input type="hidden" name="tech_id" value={techId} />
                {/* tech_id 필드 추가 */}
                <div className={askStyles.formTxt}>
                  <input
                    type="hidden"
                    id="name"
                    name="reply_name"
                    required
                    placeholder="이름"
                    value="Ysystems"
                  />
                </div>
                <div className={askStyles.formTxt}>
                  <input
                    type="text"
                    id="name"
                    name="reply_title"
                    required
                    placeholder="제목"
                  />
                </div>
                <div className={askStyles.formFile}>
                  <input type="file" id="file" name="reply_file" multiple />
                </div>
                <div className={askStyles.formMsg}>
                  <textarea
                    name="reply_content"
                    id="ask"
                    placeholder="답글 내용을 입력하세요."
                  ></textarea>
                </div>
                <div className={askStyles.formSubmit}>
                  <button type="submit">답글 작성하기</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Reply;
