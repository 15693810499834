import React, { useEffect, useRef } from "react";
import main5Styles from "../../assets/pagesStyle/main/main5.module.css";
import ysystems from "../../assets/video/test.mp4";
import Nav from "../../components/Nav";

const Main5 = ({ isActive }) => {
  const main5Ref = useRef(null);
  useEffect(() => {
    if (isActive) {
      // 스와이퍼 슬라이드가 활성화되었을 때 애니메이션 효과를 부여
      main5Ref.current.classList.add(main5Styles.active);
    } else {
      // 비활성화 시 클래스 제거
      main5Ref.current.classList.remove(main5Styles.active);
    }
  }, [isActive]);
  return (
    <>
      <Nav isActive={isActive} />
      <div ref={main5Ref} className={main5Styles.main5}>
        <h1>
          Beyond Technology
          <br /> Ysystem
        </h1>
        <video autoPlay loop muted playsInline className={main5Styles.video}>
          <source src={ysystems} type="video/mp4" />
        </video>
      </div>
    </>
  );
};

export default Main5;
