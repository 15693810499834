import fnb from "../assets/images/members/fnb.svg";
import gtech from "../assets/images/members/gtech.svg";
import hikvision from "../assets/images/members/hikvision.svg";
import imazero from "../assets/images/members/imazero.svg";
import itrun from "../assets/images/members/itrun.svg";
import netgreen from "../assets/images/members/netgreen.svg";
import ohyoon from "../assets/images/members/ohyoon.svg";
import starrich from "../assets/images/members/starrich.svg";
import ytkon from "../assets/images/members/ytkon.png";
import pcot from "../assets/images/members/pc&ot.svg";

const membersData = [
  {
    id: 1,
    img: fnb,
  },
  {
    id: 2,
    img: gtech,
  },
  {
    id: 3,
    img: hikvision,
  },
  {
    id: 4,
    img: imazero,
  },
  {
    id: 5,
    img: itrun,
  },
  {
    id: 6,
    img: netgreen,
  },
  {
    id: 7,
    img: ohyoon,
  },
  {
    id: 8,
    img: pcot,
  },
  {
    id: 9,
    img: starrich,
  },
  {
    id: 10,
    img: ytkon,
  },
];

export default membersData;
