import React, { useEffect, useRef } from "react";
import Nav from "../../components/Nav";
import main3Styles from "../../assets/pagesStyle/main/main3.module.css";
import machine from "../../assets/images/machine.png";
import ai from "../../assets/images/AI.png";
import solution from "../../assets/images/solution.png";

const Main3 = ({ isActive }) => {
  const main3Ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      // 스와이퍼 슬라이드가 활성화되었을 때 애니메이션 효과를 부여
      main3Ref.current.classList.add(main3Styles.active);
    } else {
      // 비활성화 시 클래스 제거
      main3Ref.current.classList.remove(main3Styles.active);
    }
  }, [isActive]);
  return (
    <>
      <Nav />
      <div ref={main3Ref} className={main3Styles.main3}>
        <h1>신기술을 통해 만드는 더 나은 미래</h1>
        <div className={main3Styles.main3Flex}>
          <div className={main3Styles.main3List1}>
            {/* 이미지 Link 설정 */}
            <div>
              <img src={machine} alt="machine" />
            </div>
            <div>
              <h2>기계설비</h2>
              <p>품질과 가격에 최적화된 설계/조립 기술</p>
            </div>
          </div>
          <div className={main3Styles.main3List2}>
            {/* 이미지 Link 설정 */}
            <div>
              <img src={ai} alt="ai" />
            </div>
            <div>
              <h2>딥러닝/인공지능</h2>
              <p>향상되고 고도화된 모델링 기술</p>
            </div>
          </div>
          <div className={main3Styles.main3List3}>
            {/* 이미지 Link 설정 */}
            <div>
              <img src={solution} alt="solution" />
            </div>
            <div>
              <h2>솔루션</h2>
              <p>고객맞춤형 최적화 IT솔루션 기술</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main3;
