import React, { useRef } from "react";
import main6Styles from "../../assets/pagesStyle/main/main6.module.css";
import right from "../../assets/images/rightArrow.svg";
import left from "../../assets/images/leftArrow.svg";
import Nav from "../../components/Nav";
import { Swiper, SwiperSlide } from "swiper/react";
import membersData from "../../data/membersData";
// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import { Autoplay, Scrollbar } from "swiper/modules";

const Main6 = () => {
  const swiperRef = useRef(null);
  const slideNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  // 파트너 로고 배열

  return (
    <>
      <Nav />
      <div className={main6Styles.main6}>
        <div className={main6Styles.main6Box}>
          <div className={main6Styles.txtBox}>
            <h1>Business Members</h1>
            <h2>
              와이시스템즈와 같이 <br />
              미래를 만들어가는 기업입니다.
            </h2>
            <div className={main6Styles.btn}>
              <img src={left} alt="leftArrow" onClick={slidePrev} />
              <img src={right} alt="rightArrow" onClick={slideNext} />
            </div>
          </div>
          {/* 스와이퍼 부분 */}
          <Swiper
            ref={swiperRef}
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            breakpoints={{
              390: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Scrollbar]}
            className="main6Swiper"
          >
            {membersData.map((partner, index) => (
              <SwiperSlide key={index} className={main6Styles.partner}>
                <div className={main6Styles.logo}>
                  <img src={partner.img} alt={partner.img} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default Main6;
