import React from "react";
import main1Styles from "../../assets/pagesStyle/main/main1.module.css";

const Main1 = () => {
  return (
    <div className={main1Styles.main1} style={main1Styles}>
      <div className={main1Styles.txtBox}>
        <p>Proprietary technology</p>
        <h1>기술의 미래를 선도하는 와이시스템즈</h1>
        <p>
          첨단자동화솔루션을통해 산업의미래를혁신하고,
          <br />
          지속가능한발전을추구합니다.
        </p>
        <button className={main1Styles.btn}></button>
      </div>
    </div>
  );
};

export default Main1;
