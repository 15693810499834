const recruitData = [
  {
    id: 3,
    title: "머신비전 개발자 채용공고(신입 및 경력)",
    date: "~ 2024.07.23 (마감)",
    link: "https://www.saramin.co.kr/zf_user/jobs/relay/view?rec_idx=48460906&view_type=list",
  },
  {
    id: 2,
    title: "[자동화장비] C#, C+ 정규직 채용",
    date: "~ 2024.07.23 (마감)",
    link: "https://www.saramin.co.kr/zf_user/jobs/relay/view?rec_idx=48460907&view_type=list",
  },
  {
    id: 1,
    title: "경영지원 신입/경력 채용",
    date: "~ 2024.06.23 (마감)",
    link: "https://www.saramin.co.kr/zf_user/jobs/relay/view?rec_idx=48460907&view_type=list",
  },
];

export default recruitData;
