import React, { useEffect, useState } from "react";
import historyStyles from "../../assets/pagesStyle/intro/history.module.css";
import "swiper/css";
import "swiper/css/pagination";
import "../../assets/swiper/styles.css";
import { Mousewheel, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Footer from "../footer/Footer";
import historyData from "../../data/historyData";

const History = () => {
  const [isLastSlide, setIsLastSlide] = useState(false);
  const [isSwiperEnabled, setIsSwiperEnabled] = useState(true);

  const handleSlideChange = (swiper) => {
    const isLast = swiper.activeIndex === swiper.slides.length - 1;
    const isFirst = swiper.activeIndex === 0;
    setIsLastSlide(isLast);

    if (isLast) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else if (isFirst) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 1500);
    } else {
      window.scrollTo({ top: 200, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSwiperEnabled(window.innerWidth > 800);
    };

    handleResize(); // 초기 로드 시 한번 실행
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isLastSlide) {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLastSlide]);

  return (
    <>
      <div className={historyStyles.history}>
        <div className={historyStyles.historyTop}>
          <h1>연혁</h1>
          <p>
            다양한 분야의 최고 전문가들이 모여 어제와 오늘, 그리고 내일을
            만듭니다.
          </p>
        </div>
        {isSwiperEnabled ? (
          <Swiper
            direction={"vertical"}
            slidesPerView={1}
            mousewheel={true}
            pagination={{
              clickable: true,
            }}
            modules={[Mousewheel, Pagination]}
            className="historySwiper"
            onSlideChange={handleSlideChange}
          >
            {historyData.map((slide) => (
              <SwiperSlide key={slide.id}>
                <div className={historyStyles.flex}>
                  <img src={slide.image} alt={`historyBg${slide.id}`} />
                  <div className={historyStyles.txtBox}>
                    <h1>{slide.title}</h1>
                    <div className={historyStyles.line}></div>
                    <ul>
                      {slide.items.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <div className={historyStyles.historyContent}>
            {historyData.map((slide) => (
              <div key={slide.id} className={historyStyles.flex}>
                <img src={slide.image} alt={`historyBg${slide.id}`} />
                <div className={historyStyles.txtBox}>
                  <h1>{slide.title}</h1>
                  <div className={historyStyles.line}></div>
                  <ul>
                    {slide.items.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default History;
