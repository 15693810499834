import React, { useEffect, useState } from "react";
import mobileNavStyles from "../assets/pagesStyle/nav/mobileNav.module.css"; // CSS 모듈 가져오기
import mobileNav from "../assets/images/mobileNav.svg";
import mobileNavCross from "../assets/images/mobileNavCross.svg";

import { Link } from "react-router-dom";

const MobileNav = ({ isActive }) => {
  const [isNavActive, setIsNavActive] = useState(false);

  const handleNavToggle = () => {
    setIsNavActive(!isNavActive);
  };

  useEffect(() => {
    if (isNavActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    // 컴포넌트 언마운트 시 스크롤 상태 복원
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isNavActive]);
  return (
    <div
      className={`${mobileNavStyles.mobileNavBar} ${
        isActive ? mobileNavStyles.activeNav : ""
      }`}
    >
      <div className={mobileNavStyles.logoBox}>
        <Link to="/">
          <h1>로고</h1>
        </Link>
      </div>
      <div className={mobileNavStyles.clickMobileNav} onClick={handleNavToggle}>
        {isNavActive ? (
          <img src={mobileNavCross} alt="모바일 메뉴 닫기" />
        ) : (
          <img src={mobileNav} alt="모바일 메뉴 열기" />
        )}
        <div
          className={`${mobileNavStyles.MobileLink} ${
            isNavActive ? mobileNavStyles.active : ""
          }`}
        >
          <Link to="/intro" className={mobileNavStyles.link}>
            회사소개
          </Link>
          <Link to="/product" className={mobileNavStyles.link}>
            제품소개
          </Link>
          <Link to="/promote" className={mobileNavStyles.link}>
            홍보센터
          </Link>
          <Link to="/customer" className={mobileNavStyles.link}>
            고객지원
          </Link>
          <Link to="/recruit" className={mobileNavStyles.link}>
            인재채용
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
