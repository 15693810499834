import product1 from "../assets/images/product/product1.png";
import product2 from "../assets/images/product/product2.png";
import product3 from "../assets/images/product/product3.png";
import product4 from "../assets/images/product/product4.png";
import product5 from "../assets/images/product/product5.png";
import product6 from "../assets/images/product/product6.png";
import product7 from "../assets/images/product/product7.png";
// import product8 from "../assets/images/product/product8.png";
// import product9 from "../assets/images/product/product9.png";
import product10 from "../assets/images/product/product10.png";

const productData = [
  {
    id: 1,
    img: product1,
    title: "Standalone Custom Microscope​",
    info: (
      <p>
        Sampling
        <br /> Dimension Measurement
        <br /> Single Axis Specification
        <br /> PC/Monitor Addable
        <br /> Custom PC Software Development Available
      </p>
    ),
  },
  {
    id: 2,
    img: product2,
    title: "Non-Standalone Custom Microscope (Medium)​",
    info: (
      <p>
        Sampling
        <br /> Dimension Measurement
        <br /> PC/Monitor Required Specification
        <br /> Custom PC Software Development Available
      </p>
    ),
  },
  {
    id: 3,
    img: product3,
    title: "Non-Standalone Custom Microscope (Small)​",
    info: (
      <p>
        Sampling
        <br /> Dimension Measurement
        <br /> Single Axis Specification
        <br /> PC/Monitor Addable
        <br /> Custom PC Software Development Available
      </p>
    ),
  },
  {
    id: 4,
    img: product4,
    title: "PCB CLEANING & SORTING SYSTEM​",
    info: (
      <p>
        CASSETTE & STACK
        <br /> LOADING/UNLOADING
        <br />
        ​VISION : 16K TDI LINE SCAN CAMERA 양면​
        <br />
        AIR KNIFE BLOW CLEANER
        <br />
        ​PCB SIZE : W40~100, L100~300​
        <br /> X-OUT SORTING​
      </p>
    ),
  },
  {
    id: 5,
    img: product5,
    title: "원판 PCB 2D MARKING SYSTEM​",
    info: (
      <p>
        PANEL TRAY CART TO PANEL TRAY CART​
        <br />
        GREEN LASER MARKING​​
        <br />
        MARKING ACCURACY +/- 50um​​
        <br />
        16K LINE SCAN CAMERA & 4M 2D VISION​​
        <br />
        PCB SIZE : 400~600​
      </p>
    ),
  },
  {
    id: 6,
    img: product6,
    title: "PCB 2D AFVI​",
    info: (
      <p>
        CASSETTE & STACK LOADING/UNLOADING​
        <br />
        DUAL 16K TDI LINE SCAN CAMERA​
        <br />
        PCB SIZE : W40~100, L100~300​
        <br />
        X-OUT SORTING​
        <br />
      </p>
    ),
  },
  {
    id: 7,
    img: product7,
    title: "PCB 3D AFVI​",
    info: (
      <p>
        CASSETTE & STACK LOADING/UNLOADING​ <br />
        2D SCAN : 16K TDI LINE SCAN CAMERA​ <br />
        3D SCAN : 16 kHz(63 µ s)*2​ <br />
        PCB SIZE : W40~100, L100~300​ <br />
        X-OUT SORTING​
      </p>
    ),
  },
  // {
  //   id: 8,
  //   img: product8,
  //   title: "제품명",
  //   info: <p>추가가 필요합니다.​</p>,
  // },
  // {
  //   id: 9,
  //   img: product9,
  //   title: "제품명",
  //   info: <p>추가가 필요합니다.​</p>,
  // },
  {
    id: 10,
    img: product10,
    title: "REEL TO REEL INSPECTION SYSTEM",
    info: (
      <p>
        PKG TYPE : SOT, SC, SOD, SOIC, QFN/MLP,
        <br /> LED, ETC
        <br />
        REEL WIDTH : 8~22
        <br />
        UPH : 25K~45K
        <br />
        ACCURACY : 35um
        <br />
        REJECT PKG LABEL ATTACH
        <br />
      </p>
    ),
  },
];

export default productData;
