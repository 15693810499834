import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import promoteStyles from "../../assets/pagesStyle/promote/promote.module.css";
import {} from "react-router-dom";
import newsStyles from "../../assets/pagesStyle/promote/news.module.css";
import Footer from "../../pages/footer/Footer";
import axios from "axios";
import Login from "../../assets/hooks/Login";
import useAuth from "../../assets/hooks/useAuth";

const NewsAdmin = () => {
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState("NewsAdmin");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await axios.post(
        "https://y-system.synology.me:8080/news",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("게시물 작성이 완료되었습니다.");
        navigate("/admin", { state: { isActive: "NewsAdmin" } });
      } else {
        alert("제출 중 오류가 발생했습니다.");
      }
    } catch (error) {
      alert("제출 중 오류가 발생했습니다.");
    }
  };

  const handleLinkClick = (link) => {
    setIsActive(link);
  };
  const backBtn = () => {
    navigate("/admin");
  };

  if (!isAuthenticated) {
    return <Login />;
  }

  return (
    <>
      <div className={promoteStyles.promote}>
        <div className={promoteStyles.promoteTop}>
          <h2>Admin</h2>
          <h1>뉴스 작성</h1>
        </div>
        <div className={promoteStyles.promoteFlex}>
          <Link
            className={isActive === "NewsAdmin" ? promoteStyles.active : ""}
            onClick={() => handleLinkClick("NewsAdmin")}
          >
            뉴스 작성
          </Link>
        </div>
        <div className={newsStyles.newsDetail}>
          <form
            onSubmit={handleSubmit}
            method="post"
            action="https://y-system.synology.me:8080/news"
            enctype="multipart/form-data"
          >
            <div className={newsStyles.newsDetailTitle}>
              <input
                type="text"
                id="news_title"
                name="news_title"
                placeholder="뉴스 제목란 입니다."
                required
              />
            </div>
            <div className={newsStyles.formFile}>
              <h2>파일을 첨부하지 않으면 기본이미지가 삽입 됩니다.</h2>

              <input type="file" id="file" name="news_file" multiple />
            </div>
            <h1>제목이 나오는 공간 입니다.</h1>
            <div className={newsStyles.formMsg}>
              <textarea
                name="news_content"
                id="news_content"
                placeholder="뉴스 내용을 입력하세요."
              ></textarea>
            </div>
            <div className={newsStyles.formSubmit}>
              <button type="submit">뉴스 작성하기</button>
            </div>
          </form>
          <button onClick={backBtn}>뒤로가기</button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsAdmin;
