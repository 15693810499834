import React, { useEffect, useState } from "react";
import newsStyles from "../../assets/pagesStyle/promote/news.module.css";
import Footer from "../footer/Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import useAuth from "../../assets/hooks/useAuth";

const News = () => {
  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4); // 초기값을 4로 설정
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    async function getNews() {
      const response = await axios.get(
        "https://y-system.synology.me:8080/news"
      );
      const data = response.data;

      // 데이터가 날짜를 포함하고 있다고 가정
      // 최신순으로 정렬 (내림차순)
      const sortedData = data.sort(
        (a, b) => new Date(b.news_id) - new Date(a.news_id)
      );

      setNews(sortedData);
    }
    getNews();
  }, []);

  useEffect(() => {
    // 화면 크기에 따라 itemsPerPage를 조정하는 함수
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setItemsPerPage(2); // 800px 이하일 경우 2개로 설정
      } else {
        setItemsPerPage(4); // 800px 이상일 경우 4개로 설정
        setCurrentPage(0); // 800px 이상으로 돌아가면 페이지를 1페이지로 초기화
      }
    };

    // 초기 실행
    handleResize();

    // 윈도우 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalPages = Math.ceil(news.length / itemsPerPage);

  const displayedNews = news.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <>
      <div className={newsStyles.news}>
        <div className={newsStyles.newsBox}>
          <div className={newsStyles.txtBox}>
            <h1>새소식</h1>
            <p>와이시스템 새소식을 확인해보세요.</p>
            {isAuthenticated && <Link to="/admin/news">뉴스 생성</Link>}
          </div>
          <div className={newsStyles.newsGridContainer}>
            {displayedNews.map((i, id) => (
              <div key={i.news_id} className={newsStyles.newsGrid}>
                <Link to={`/news/${i.news_id}`}>
                  <img
                    src={`https://y-system.synology.me:8080/news/${i.news_file}`}
                    alt={`${i.news_title} - ${id + 1}`}
                  />
                </Link>
                <h2>{i.news_title}</h2>
              </div>
            ))}
          </div>
          <div className={newsStyles.pagination}>
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => handlePageClick(i)}
                className={currentPage === i ? newsStyles.active : ""}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default News;
