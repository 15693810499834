import sinhan from "../assets/images/partners/sinhan.svg";
import oms from "../assets/images/partners/oms.svg";
import samsung from "../assets/images/partners/samsung.svg";
import sk from "../assets/images/partners/sk.svg";
import wooone from "../assets/images/partners/wooone.svg";
import wim from "../assets/images/partners/wim.svg";

const partnersData = [
  { id: 1, img: sinhan },
  { id: 2, img: oms },
  { id: 3, img: samsung },
  { id: 4, img: sk },
  { id: 5, img: wooone },
  { id: 6, img: wim },
];

export default partnersData;
