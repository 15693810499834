import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Nav from "./Nav";
import promoteStyles from "../assets/pagesStyle/promote/promote.module.css";
import downloadStyles from "../assets/pagesStyle/promote/download.module.css";
import downBtn from "../assets/images/downBtn.svg";
import Footer from "../pages/footer/Footer";
import Loading from "../pages/loading/Loading";
import axios from "axios";
import useAuth from "../assets/hooks/useAuth";
import MobileNav from "./MobileNav";

const DownloadDetail = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
  const [download, setDownload] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isActive, setIsActive] = useState("Download");
  const { isAuthenticated } = useAuth(); // 로그인 상태 가져오기
  useEffect(() => {
    async function getDownload() {
      const response = await axios.get(
        "https://y-system.synology.me:8080/download"
      );
      const data = response.data;
      setDownload(data);
    }
    getDownload();
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (location.state?.activeTab) {
      setIsActive(location.state.activeTab);
    }
  }, [location.state]);

  useEffect(() => {
    async function viewCount() {
      try {
        await axios.post(
          `https://y-system.synology.me:8080/download/${id}/view`
        );
      } catch (error) {
        console.error("조회수를 가져올 수 없습니다!:", error);
      }
    }
    viewCount();
  }, [id]);

  const handleLinkClick = (link) => {
    setIsActive(link);
  };

  const downloadItem = download.find(
    (item) => item.download_id === parseInt(id)
  );
  if (!downloadItem) {
    return <Loading />;
  }

  const currentIndex = download.findIndex(
    (item) => item.download_id === parseInt(id)
  );
  const prevItem = download[currentIndex - 1];
  const nextItem = download[currentIndex + 1];

  const listBtn = () => {
    navigate("/promote");
  };

  const goToPrev = () => {
    if (prevItem) {
      navigate(`/promote/download/${prevItem.download_id}`);
    }
  };

  const goToNext = () => {
    if (nextItem) {
      navigate(`/promote/download/${nextItem.download_id}`);
    }
  };

  const deletePost = async () => {
    try {
      await axios.delete(
        `https://y-system.synology.me:8080/download/delete/${id}`
      );
      navigate("/promote");
      alert("게시물이 삭제되었습니다.");
    } catch (error) {
      console.error("글 지우기에 실패하였습니다.:", error);
    }
  };

  const fileArray = downloadItem.download_file.split(",");

  const isImage = (filename) => {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    return imageExtensions.some((extension) =>
      filename.toLowerCase().endsWith(extension)
    );
  };

  return (
    <>
      <div className={promoteStyles.promote}>
        {isMobile ? <MobileNav isActive={true} /> : <Nav isActive={true} />}
        <div className={promoteStyles.promoteTop}>
          <h2>promote</h2>
          <h1>홍보센터</h1>
        </div>
        <div className={promoteStyles.promoteFlex}>
          <Link
            className={isActive === "News" ? promoteStyles.active : ""}
            onClick={() => handleLinkClick("News")}
          >
            새소식
          </Link>
          <Link
            className={isActive === "Download" ? promoteStyles.active : ""}
            onClick={() => handleLinkClick("Download")}
          >
            자료실
          </Link>
        </div>
        <div className={downloadStyles.downDetail}>
          <h1>자료실</h1>
          <div className={downloadStyles.downDetailTitle}>
            <h1>{downloadItem.download_title}</h1>
          </div>
          <div className={downloadStyles.downDetailFlex}>
            <div className={downloadStyles.dataTxt}>
              <h3>작성자</h3>
              <p>{downloadItem.download_name}</p>
            </div>
            <div className={downloadStyles.dataTxt}>
              <h3>작성일</h3>
              <p>{downloadItem.download_date}</p>
            </div>
            <div className={downloadStyles.dataTxt}>
              <h3>조회</h3>
              <p>{downloadItem.download_view}</p>
            </div>
          </div>
          <div className={downloadStyles.contentBox}>
            <p>{downloadItem.download_content}</p>
            {fileArray.map((file, index) =>
              isImage(file) ? (
                <img
                  key={index}
                  src={`https://y-system.synology.me:8080/download/${file}`}
                  alt={`${downloadItem.download_title} - ${index + 1}`}
                />
              ) : null
            )}
          </div>
          <div className={downloadStyles.fileBox}>
            <h3>첨부파일</h3>
            {fileArray.map((file, index) => (
              <a
                key={index}
                href={`https://y-system.synology.me:8080/download/${file}`}
                download
              >
                첨부파일{index}-{file.slice(0, 15)}...
                <img
                  src={downBtn}
                  alt="다운로드 버튼"
                  className={downloadStyles.downBtn}
                />
              </a>
            ))}
          </div>
          <div className={downloadStyles.btnBox}>
            <div className={downloadStyles.detailBtn}>
              <button onClick={goToPrev} disabled={!prevItem}>
                이전
              </button>
              <button onClick={listBtn}>목록</button>
              <button onClick={goToNext} disabled={!nextItem}>
                다음&#62;
              </button>
            </div>
            <div className={downloadStyles.txtBtn}>
              {/* <button>글수정</button> */}
              {isAuthenticated && <button onClick={deletePost}>글삭제</button>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadDetail;
