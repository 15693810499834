import React, { useState } from "react";
import faqStyles from "../../assets/pagesStyle/customer/faq.module.css";
import Footer from "../footer/Footer";
import faqData from "../../data/faqData";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className={faqStyles.faq}>
        <div className={faqStyles.faqBox}>
          <div className={faqStyles.faqBoxTxt}>
            <h1>FAQ</h1>
            <p>자주하는 질문입니다.</p>
          </div>

          <div className={faqStyles.toggles}>
            {faqData.map((item, index) => (
              <div
                key={index}
                className={`${faqStyles.toggleBox} ${
                  activeIndex === index ? faqStyles.active : ""
                }`}
              >
                <div
                  className={faqStyles.txtBox}
                  onClick={() => toggleFAQ(index)}
                >
                  <h2>{item.question}</h2>
                  <p
                    className={`${faqStyles.txt} ${
                      activeIndex === index ? faqStyles.active : ""
                    }`}
                  >
                    {item.answer}
                  </p>
                </div>
                <div
                  className={`${faqStyles.btn} ${
                    activeIndex === index ? faqStyles.active : ""
                  }`}
                  onClick={() => toggleFAQ(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
