import React from "react";
import footerStyles from "../../assets/pagesStyle/footer/footer.module.css";
import logo from "../../assets/images/footerLogo.png";
import sns1 from "../../assets/images/instargram.png";
import sns2 from "../../assets/images/facebook.png";
import sns3 from "../../assets/images/youtube.png";
import sns4 from "../../assets/images/kakaotalk.png";
import { Link } from "react-router-dom";

const Footer = ({ className }) => {
  return (
    <>
      <div className={`${footerStyles.footer} ${className}`}>
        <div className={footerStyles.footerBox}>
          <div className={footerStyles.logos}>
            <img src={logo} alt="footerLogo" />
            <div className={footerStyles.sns}>
              <img src={sns1} alt="instargram" />
              <img src={sns2} alt="facebook" />
              <img src={sns3} alt="youtube" />
              <img src={sns4} alt="kakaotalk" />
            </div>
          </div>
          <div className={footerStyles.info}>
            <h1>와이시스템즈</h1>
            <div className={footerStyles.txtBox}>
              <p>대표 : 최치영</p>
              <p>사업자등록번호 : 643-86-02978</p>
              <p>주소 : 평택시 고덕중앙로 290, 9층 27호</p>
            </div>
          </div>
          <div className={footerStyles.contact}>
            <h1>contact</h1>
            <div className={footerStyles.txtBox}>
              <p>TEL : 010 2528 9281</p>
              <p>Email : cychoi@y-system.co.kr</p>
            </div>
          </div>
        </div>
      </div>
      <div className={footerStyles.copyright}>
        <Link to="/admin">
          <h3>
            COPYRIGHT © <strong> Ysystems</strong> All Rights Reserved.
          </h3>
        </Link>
      </div>
    </>
  );
};

export default Footer;
